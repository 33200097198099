<template>
  <CForm>
    <CRow>
      <CCol md="12">
        <CInput
          label="Contraseña Actual:"
          type="password"
          :lazy="false"
          :value.sync="$v.form.password.$model"
          :isValid="checkIfValid('password')"
          placeholder=""
          autocomplete="password"
          invalidFeedback="La contraseña actual debe tener al menos 6 caracteres."
      />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <CInput
          label="Nueva Contraseña:"
          type="password"
          :lazy="false"
          :value.sync="$v.form.password2.$model"
          :isValid="checkIfValid('password2')"
          placeholder=""
          autocomplete="password2"
          invalidFeedback="La nueva contraseña debe tener al menos 6 caracteres."
      />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <CInput
          label="Confirmar Nueva Contraseña:"
          type="password"
          :lazy="false"
          :value.sync="$v.form.password3.$model"
          :isValid="checkIfValid('password3')"
          placeholder=""
          autocomplete="password3"
          invalidFeedback="La confirmación de nueva contraseña debe tener al menos 6 caracteres y debe coincidir con el campo anterior."
      />
      </CCol>
    </CRow>
    <CRow><CCol md="12"><br></CCol></CRow>
    <CRow>
      <CCol md="12">
        <CButton @click="storePasswordForm" color="info" style="float: right;">Actualizar Contraseña</CButton>
      </CCol>
    </CRow>
  </CForm>
  </CRow>
</template>

<script>
import store from '../../store';
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email, sameAs} from "vuelidate/lib/validators"
import account_ws from '../../services/account';

export default {
  name: 'MyPasswordForm',
  components: { },
  props: {

  },
  data() {
    return {
      form: {
          password: '',
          password2: '',
          password3: ''
      },
      submitted: false
    }
  },
  mounted: async function() {

  },
  methods: {
    async storePasswordForm () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        let payload = {
          old_password: this.form.password,
          new_password: this.form.password2
        }

        let response = await account_ws.setPassword(payload);

        let data = response.data;

        this.showToast(response.type, response.message);
      }
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      password2: {
        required,
        minLength: minLength(6)
      },
      password3: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs('password2')
      },
    }
  },
}
</script>
