<template>
  <CRow>
    <CCol md="12">
      <CCard style="min-height: 600px;">
        <CCardBody>
          <ul class="nav nav-tabs justify-content-end">
            <li @click="changeTab('general')" class="nav-item">
              <a class="nav-link" :class="tab == 'general' ? 'active' : ''" aria-current="page">Información General</a>
            </li>   
            <li @click="changeTab('password')" class="nav-item">
              <a class="nav-link" :class="tab == 'password' ? 'active' : ''">Contraseña</a>
            </li>        
          </ul>
          <br>
          <div v-show="tab == 'general'" class="container-fluid" style="padding: 10px;">
            <CForm>
              <CRow>
                <CCol md="4">
                  <CInput
                    label="Nombre:"
                    :lazy="false"
                    :value.sync="$v.form.name.$model"
                    :isValid="checkIfValid('name')"
                    placeholder=""
                    autocomplete="name"
                    invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
                </CCol>
                <CCol md="4">
                  <CInput
                    label="Teléfono o Celular:"
                    :lazy="false"
                    :value.sync="$v.form.phone.$model"
                    :isValid="checkIfValid('phone')"
                    placeholder=""
                    autocomplete="phone"
                    invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
                </CCol>
                <CCol md="4">
                  <CInput
                    label="Correo Electrónico:"
                    :lazy="false"
                    :value.sync="$v.form.email.$model"
                    :isValid="checkIfValid('email')"
                    placeholder=""
                    autocomplete="email"
                    invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
                </CCol>
              </CRow>
              <CRow><CCol md="12"><br></CCol></CRow>
             <CRow>
              <CCol md="12">
                <CButton @click="storeInfoForm" color="info" style="float: right;">Actualizar Información</CButton>
              </CCol>
            </CRow>
            </CForm>
          </div>
          <div v-show="tab == 'password'" class="container-fluid" style="padding: 10px;">
            <MyPasswordForm ref="passwordForm"></MyPasswordForm>
          </div>                  
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import store from '../../store';
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"
import general_ws from '../../services/general';
import account_ws from '../../services/account';
import MyPasswordForm from './MyPasswordForm.vue'

export default {
  name: 'MyAccountForm',
  components: { MyPasswordForm },
  props: {
    caption: {
      type: String,
      default: 'Table'
    },
  },
  data() {
    return {
      tab: 'general',
      openForm: false,
      isEdit: false,
      form: this.getEmptyForm(),
      form_passwords: {
          password: '',
          password2: '',
          password3: ''
      },
      stamps: 0,
      deadline: '2023-12-31',
      submitted: false,
      toasts: [],
    }
  },
  mounted: async function() {
    this.loading();

    let response = await account_ws.getInfo(); 

    if(response.type == "success"){
      this.form.name = response.data.name;
      this.form.email = response.data.email;
      this.form.phone = response.data.phone;
    }

    this.loaded();
  },
  methods: {
    async storeInfoForm () {
      this.$v.$touch();

      if (this.isValid) {
        this.loading();

        this.submitted = true;

        let response = await account_ws.setInfo(this.form);

        this.loaded();
        this.showToast(response.type, response.message);

        //location.reload();
      }
    },
    async storeLogoForm () {
      if(this.form.logo != undefined){
        this.loading();

        const form = document.getElementById("logo_form");

        const formData = new FormData(form);

        let response = await account_ws.changeLogo(formData);

        let data = response.data;

        if(response.type == "success"){
          this.logo = data.url_logo;
          this.logo_thumb = data.url_thumb;

          this.form.logo = '';
        }

        this.loaded();
        this.showToast(response.type, response.message);
      }
    },
    async updatePassword (password_data) {
      this.loading();

      let response = await account_ws.setPassword(password_data);

      let data = response.data;

      if(response.type == "success"){
        this.logo = data.url_logo;
      }

      this.loaded();
      this.showToast(response.type, response.message);
    },
    changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;
      }
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          email: '',
          phone: ''
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    }
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    isPassValid () { return !this.$v.form_passwords.$invalid },
    isPassDirty () { return this.$v.form_passwords.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      phone: {
        integer,
        minLength: minLength(10),
        maxLength: maxLength(10)
      },
      email: {
        email
      }
    },
    form_passwords: {
      password: {
        required,
        minLength: minLength(6)
      },
      password2: {
        required,
        minLength: minLength(6)
      },
      password3: {
        required,
        minLength: minLength(6)
      },
    }
  },
}
</script>
